import { AuthUserInterface } from 'modules/auth/auth.slice';
import { useSession } from 'next-auth/react';
import { useMemo } from 'react';

export interface BusinessPartnerInternalResponseInterface {
  id: string;
  name?: string;
  kunnr: string;
}

export interface UserBusinessPartnerInternalInterface {
  isLoading: boolean;
  data: BusinessPartnerInternalResponseInterface[];
}

export const useUserCompany = (): UserBusinessPartnerInternalInterface => {
  const { data, status } = useSession();
  const isLoading = useMemo(() => status === 'loading', [status]);
  const result = (data?.user as AuthUserInterface)?.userBusinessPartnerInternals?.data.map(
    (x) => x.businessPartnerInternal,
  );

  return {
    isLoading,
    data: result,
  };
};
