import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeEnum } from 'modules/theme/enums';

export const useFormAlertStyles = makeStyles((theme: Theme) => ({
  snackbar: {
    [theme.breakpoints.up('xs')]: {
      top: 72,
    },
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    alignItems: 'center',
    '& .MuiPaper-root': {
      alignItems: 'center'
    },
    backgroundColor: theme.palette.mode === ThemeEnum.LIGHT ? 'transparent' : 'initial',
    boxShadow: theme.palette.mode === ThemeEnum.LIGHT ? 'unset' : 'initial',
    '& .MuiAlert-message': {
      color: theme.palette.mode === ThemeEnum.LIGHT ? theme.palette.common.white : theme.palette.common.white,
      padding: '0px !important',
      '& .MuiTypography-root': {
        margin: '0px !important'
      }
    },
    '& .MuiAlert-icon': {
      color: theme.palette.mode === ThemeEnum.LIGHT ? theme.palette.common.white : theme.palette.common.white,
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0 !important'
    },
    '& .MuiAlert-action': {
      padding: '0px !important'
    }
  },
}));
