import { RootState } from 'configuration/redux/reducer';
import { NotificationMessage } from 'modules/common/components/message-top-bar/interfaces';
import { clearTopbarMessage,setTopbarMessage } from 'modules/common/components/message-top-bar/message-top-bar.slice';
import { useDispatch, useSelector } from 'react-redux';

export const useTopbarMessage = () => {
  const dispatch = useDispatch();

  const topbarMessage = useSelector((state: RootState) => state.messageTopbar.message);

  const hideTopbarMessage = () => {
    dispatch(clearTopbarMessage());
  };

  const showTopbarMessage = (message: NotificationMessage) => {
    dispatch(setTopbarMessage(message));
    if (message.autoClose || message.closeAfterMs) {
      setTimeout(hideTopbarMessage, message.closeAfterMs || 5000);
    }
  };

  return { showTopbarMessage, hideTopbarMessage, topbarMessage };
};
