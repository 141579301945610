import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { PaginationInterface } from 'modules/common/interfaces';
import { requestGql } from 'modules/common/utils/request-gql';

export interface ProductQueryInterface extends PaginationInterface {
  filter?: {
    kunnr: string;
    searchText?: string;
  };
}

export interface ProductPageInterface {
  data: {
    productId: string;
    productName: string;
    kunnr: string;
  }[];
  totalCount: number;
}
export interface UseFetchProductHookInterface {
  fetchProducts: (query: ProductQueryInterface) => Promise<ProductPageInterface>;
}

export const useFetchProduct = (): UseFetchProductHookInterface => {
  const fetchProducts = (query: ProductQueryInterface): Promise<ProductPageInterface> =>
    requestGql(
      {
        query: gql`
          query Products($limit: Int, $offset: Int, $filter: ProductFilterArgType) {
            products(limit: $limit, offset: $offset, filter: $filter) {
              data {
                kunnr
                productId
                productName
              }

              totalCount
            }
          }
        `,
        variables: !isEmpty(query)
          ? {
              limit: query.limit,
              offset: query.offset,
            filter: query.filter,
            }
          : {},
      },
      null,
      'products',
    );

  return {
    fetchProducts,
  };
};
