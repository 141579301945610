import { SxProps, Typography } from '@mui/material';
import { ReactElement } from 'react';

export const CustomLabel = ({ value, styleProps }: { value: string, styleProps?: SxProps }): ReactElement =>
  <Typography
    sx={{
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '157%',
      color: '#626262',
      letterSpacing: '0.17px',
      marginBottom: '6px',
      ...(styleProps ? styleProps : {})
    }}
  >
    {value}
  </Typography>