import { Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce  from 'lodash/debounce';
import { AutocompleteTag } from 'modules/common/components/autocomplete-tag/autocomplete-tag.component';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { useAsyncOperation } from 'modules/common/hooks';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search/interfaces';
import { useFetchProduct } from 'modules/forms/hooks';
import * as React from 'react';

interface IProps {
  label?: string;
  onChange: (selectedValue: ProductAutocompleteOptionInterface[]) => void;
  value: ProductAutocompleteOptionInterface[];
  placeholder?: string;
  multiple?: boolean;
  companyKunnr?: string;
  disabled?: boolean;
}

export const ProductAutocompleteSearchMulti: React.FunctionComponent<IProps> = (props: IProps) => {
  const { fetchProducts } = useFetchProduct();
  const { onChange, placeholder, companyKunnr, disabled = false } = props;
  const [value, setValue] = React.useState<ProductAutocompleteOptionInterface[]>(props.value);
  const [inputValue, setInputValue] = React.useState('');
  const [searchText, setSearchText] = React.useState('');

  const { status, initiateOperation, isLoading } = useAsyncOperation({
    callback: fetchProducts,
  });
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();
  const resultantKunnr = React.useMemo(
    () => companyKunnr || currentBusinessPartnerInternal?.kunnr,
    [companyKunnr, currentBusinessPartnerInternal],
  );
  const { label = 'Search Product' } = props;
  const [options, setOptions] = React.useState<readonly ProductAutocompleteOptionInterface[]>([]);
  const onInputChange = (_, text: string) => setInputValue(text);
  const debouncedSetSearchText = React.useCallback(
    debounce((text) => {
      setSearchText(text);
    }, 500),
    []
  );

  React.useEffect(() => {
    if (status?.result) {
      const mappedOption = status.result.data.map((x) => ({
        id: x.productId,
        label: x.productName,
        kunnr: x.kunnr,
      }));
      setOptions(mappedOption);
    }
  }, [status]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    debouncedSetSearchText(inputValue);
  }, [inputValue, debouncedSetSearchText])

  React.useEffect(() => {
    if (currentBusinessPartnerInternal) {
      void initiateOperation({
        offset: 0,
        limit: 20,
        filter: { kunnr: resultantKunnr, searchText },
      });
    }
  }, [resultantKunnr, searchText]);


  return (
    <>
      <CustomLabel value={label} />
      <Autocomplete
        disablePortal
        disabled={disabled}
        multiple
        filterOptions={filteredOptions => filteredOptions}
        options={options}
        loading={isLoading}
        sx={{
          '& .MuiFormControl-root': {
            margin: 0
          }
        }}
        renderTags={(tagValue: ProductAutocompleteOptionInterface[], getTagProps) =>
          tagValue.map((option, index) => (
            <AutocompleteTag
              key={index}
              index={index}
              getTagProps={getTagProps}
              label={`${option.label} - ${option.id}`}
            />
          ))
        }
        onChange={(_: React.SyntheticEvent, newValue) => {
          onChange(newValue as ProductAutocompleteOptionInterface[]);
        }}
        disableCloseOnSelect={true}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        getOptionLabel={(option: ProductAutocompleteOptionInterface) => `${option?.label}`}
        isOptionEqualToValue={(
          option: ProductAutocompleteOptionInterface,
          valueCheck: ProductAutocompleteOptionInterface,
        ) => option.id === valueCheck?.id || option.label === valueCheck.label}
        renderOption={(renderOptionProps, option: ProductAutocompleteOptionInterface, { selected }) => {
          const liProps = {
            ...renderOptionProps,
            ...{ key: option?.id || 1 },
          };

          return (
            <li {...liProps}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option?.label} - {option?.id}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            sx={{ marginTop: 1, marginBottom: 1 }}
            {...params}
            size="small"
            variant="filled"
            placeholder={!value?.length && !disabled ? placeholder : ''}
          />
        )}
      />
    </>
  );
};
