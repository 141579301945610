import { AuthStateInterface, AuthUserInterface } from 'modules/auth';
import { authSelector } from 'modules/auth/selectors';
import { UserRoleEnum } from 'modules/user-invites/enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export interface UseAuthHookInterface {
  isInitializing: boolean;
  accessToken: string;
  user: AuthUserInterface;
  providerId: string | null;
  platformAccessToken: string;
  sessionUpdatedAt: number | null;
  isCustomerWithSingleBusinessPartnerInternal: boolean;
}

export const useAuth = (): UseAuthHookInterface => {
  const {
    isInitializing,
    accessToken,
    user,
    providerId,
    sessionUpdatedAt,
    platformAccessToken
  }: AuthStateInterface = useSelector(authSelector);
  const roles = useMemo(() => user?.userUserRoles?.data?.map((role) => role?.userRole?.key as UserRoleEnum), [user]);
  const isCustomer = roles?.includes(UserRoleEnum.CUSTOMER_USER);
  const isCustomerWithSingleBusinessPartnerInternal = isCustomer && user?.userBusinessPartnerInternals?.data?.map(
    item => item?.businessPartnerInternalId
  )?.length === 1;

  return {
    isInitializing,
    accessToken,
    platformAccessToken,
    user,
    providerId,
    sessionUpdatedAt,
    isCustomerWithSingleBusinessPartnerInternal
  };
};
