import { AppDispatch } from 'configuration/redux/store';
import {
  closeBusinessPartnerSidebarAction,
  setBusinessPartnerSidebarAction,
  toggleBusinessPartnerSidebarAction,
} from 'modules/current-business-partner-selector/current-business-partner.slice';
import { useDispatch } from 'react-redux';

export interface UseNotificationsActionsInterface {
  toggleBusinessPartnerSidebar: () => void;
  closeBusinessPartnerSidebar: () => void;
  setBusinessPartnerSidebar: (sidebar: boolean) => void;
}

export const useCurrentBusinessPartnerSidebar = (): UseNotificationsActionsInterface => {
  const dispatch = useDispatch<AppDispatch>();

  const toggleBusinessPartnerSidebar = () => {
    dispatch(toggleBusinessPartnerSidebarAction());
  };

  const closeBusinessPartnerSidebar = () => {
    dispatch(closeBusinessPartnerSidebarAction());
  };

  const setBusinessPartnerSidebar = (sidebar: boolean) => {
    dispatch(setBusinessPartnerSidebarAction(sidebar));
  };

  return {
    toggleBusinessPartnerSidebar,
    closeBusinessPartnerSidebar,
    setBusinessPartnerSidebar,
  };
};
