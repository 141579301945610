import { AuthUserInterface } from 'modules/auth/auth.slice';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import { useEffect, useMemo, useState } from 'react';

export interface UseDisconnectSsoProviderInterface {
  isTwoFactorAuthConfigured: boolean;
  isTwoFactorAuthenticated: boolean;
  isTwoFactorAuthEnabled: boolean
  isLoading: boolean
}

export const useUser2fa = (): UseDisconnectSsoProviderInterface => {
  const [session, setSession] = useState<Session>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void (async () => {
      const response = await getSession()
      setSession(response)
      setIsLoading(false)
    })()
  }, [])

  const user = useMemo(() => session?.user as AuthUserInterface, [session])

  const isTwoFactorAuthConfigured = useMemo(() => {
    if (user?.isTwoFactorAuthEnabled && user?.isTwoFactorAuthConfigured) {
      return true;
    }
    return false;
  }, [user, isLoading])

  const isTwoFactorAuthenticated = useMemo(() => {
    if (user?.isTwoFactorAuthEnabled && !user?.isTwoFactorAuthExpired) {
      return true;
    }
    return false;
  }, [user, isLoading])

  const isTwoFactorAuthEnabled = useMemo(() => user?.isTwoFactorAuthEnabled, [user, isLoading])

  return {
    isLoading,
    isTwoFactorAuthConfigured,
    isTwoFactorAuthenticated,
    isTwoFactorAuthEnabled
  }
}
