import { AutocompleteRenderGetTagProps, Chip, SxProps, Theme } from '@mui/material';
import { useAutocompleteTagStyles } from 'modules/common/components/autocomplete-tag/autocomplete-tag.styles';
import { ReactElement } from 'react-markdown/lib/react-markdown';

interface AutocompleteTagInterface {
  index: number;
  getTagProps: AutocompleteRenderGetTagProps;
  label: string;
  disabled?: boolean;
  chipSystemProps?: SxProps<Theme>;
}

export const AutocompleteTag = ({
  index,
  chipSystemProps,
  getTagProps,
  label,
  disabled = false,
}: AutocompleteTagInterface): ReactElement => {
  const classes = useAutocompleteTagStyles();
  return (
    <div className={classes.customChip} style={{ maxWidth: '42%' }} key={`${index}-autocomplete-tags`}>
      <Chip
        size="small"
        {...(chipSystemProps && { sx: chipSystemProps })}
        variant="filled"
        label={label}
        {...getTagProps({ index })}
        disabled={disabled}
      />
    </div>
  );
};
