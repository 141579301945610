import {
  Autocomplete,
  Checkbox,
  InputLabel,
  TextField,
} from '@mui/material';
import { AutocompleteTag } from 'modules/common/components/autocomplete-tag/autocomplete-tag.component';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import React from 'react';

export interface OptionInterface<T = any> {
  label: string;
  value: T;
}
interface IProps<T = any> {
  placeholder?: string;
  label?: string;
  value?: OptionInterface<T>[];
  onChange?: (event: OptionInterface<T>[]) => void;
  options: OptionInterface<T>[];
  multiple?: boolean; 
}

export const SelectComponent: React.FunctionComponent<IProps> = ({
  label,
  value = [],
  onChange,
  options,
  placeholder,
  multiple = true
}) => (
  <>
    <CustomLabel value={label} />
    <Autocomplete
      multiple={multiple}
      sx={{
        '& .MuiFormControl-root': {
          margin: 0
        }
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="filled"
          placeholder={value.length ? '' : placeholder || 'All Items'}
        />
      )}
      value={value}
      getOptionLabel={(option: OptionInterface) => `${option?.label}`}
      renderTags={(tagValue: OptionInterface[], getTagProps) =>
        tagValue.map((option, index) => (
          <AutocompleteTag index={index} getTagProps={getTagProps} label={`${option.label}`} />
        ))
      }
      isOptionEqualToValue={(option: OptionInterface, valueCheck: OptionInterface) => option.label === valueCheck?.label || option.value === valueCheck.value}
      disableCloseOnSelect={true}
      renderOption={(renderOptionProps, option: OptionInterface, { selected }) => {
        const liProps = {
          ...renderOptionProps,
          ...{ key: option?.label },
        };

        return (
          <li {...liProps}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option?.label}
          </li>
        );
      }}
      onChange={(_: React.SyntheticEvent, newValue: OptionInterface[]) => {
        onChange(newValue);
      }}
    />
  </>
);
