import { SxProps, Tooltip, TooltipProps } from '@mui/material';
import { ReactElement } from 'react';

interface CustomTooltipInterface extends TooltipProps {
  title: string | ReactElement;
  children: ReactElement;
  stylingProps?: SxProps;
}

export const CustomTooltip = (props : CustomTooltipInterface) : ReactElement => {
  const { title, children, stylingProps = {}, placement = 'top', ...otherTooltipProps} = props; 
  return (
    <Tooltip
      title={title}
      placement={placement}
    sx={{ ...stylingProps, container: 'body' }}
      arrow
      {...otherTooltipProps}
    >
      {children}
    </Tooltip>
  )
}