import { roqMakeStyles } from 'modules/common/utils';

export const useAutocompleteTagStyles = roqMakeStyles(() => ({
  customChip: {
    '& .MuiChip-filled': {
      borderRadius: '2px',
      backgroundColor: '#CCDEE3',
      fontSize: '12px',
      color: '#1F4556',
      '& svg': {
        color: '#006173',
      },
    },
    maxWidth: '100%',
    overflow: 'hidden',
  },
}));
